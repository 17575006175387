import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header style={{height: '15vh', display: 'flex', alignItems: 'flex-end'}}>
      <h1 style={{ 
            color: "black",
            textDecoration: `none`,
            fontSize: '3.5rem',
            fontFamily: 'courier',
            fontWeight: '500',
            margin: '0',
            padding: '0'
          }}>
          {siteTitle}
      </h1>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
