/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"


import Header from "./header"
import "./layout.css"
import "./global.css"


const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          branch
          author {
            name
            website
          }
          telephone
          address {
            street
            number
            zip
            town
          }
        }
      }
    }
  `)

  return (
    <>
      <Header  siteTitle={data.site.siteMetadata.title} />
      <div >
        <main >{children}</main>
        <footer
          style={{display: "flex"}}>
            <div style={{ alignSelf: "end" }}>
              © {new Date().getFullYear()}{" "}
              <a style={{color: 'black'}} href={data.site.siteMetadata.author.website}>
                {data.site.siteMetadata.author.name}
              </a>
            </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
